import { useContext } from 'react';
import Head from 'next/head';

import config from 'config';
import { premiumSerwerSortValues } from 'consts/sort';
import withMetatags from 'core/hocs/withMetatags';
import { ConfigContext } from '@providers/ConfigProvider';
import { FilesContext } from '@providers/FilesProvider';
import RecommendedProducts from '@components/RecommendedProducts';
import PromotionBanners from '@components/PromotionBanners';
import AboutCompany from '@components/AboutCompany';
import ClientsAboutUs from '@components/ClientsAboutUs';
import TrustedUs from '@components/TrustedUs';
import BusinessStandards from '@components/BusinessStandards';
import HomepageProducts from '@components/HomepageProducts';
import Section from '@components/Section';
import ArticleCategoryPreview from '@components/ArticleCategoryPreview';
import ConfiguratorProcess from '@components/ConfiguratorProcess';
import VariantsList from '@components/VariantsList';
import { FormattedMessage } from 'utils/intl';
import getMappedPromotions from 'utils/getMappedPromotions';
import getStructuredData from 'utils/getStructuredData';
import resolveImageUrl from 'utils/resolveImageUrl';
import map from 'utils/map';

import PremiumSerwerBanner from './components/PremiumSerwerBanner';
import CommonBanner from './components/CommonBanner';
import DellBanner from './components/DellBanner';
import FirewallBanner from './components/FirewallBanner';
import SearchEngineText from './components/SearchEngineText';
import classes from './Home.module.scss';
import Props from './Home.types';
import messages from './Home.messages';
import { getHomeStructuredData, getSearchBoxStructuredData } from './Home.utils';

const { routes } = config;

const Home = ({
  trustedUsCount,
  variantsPromotedCount,
  testimonials,
  homepageProducts,
  numbers = [],
  articleCategories = [],
  banners = [],
  searchData,
}: Props): JSX.Element => {
  const { appUrl, isBiznesowe, isDellowo, isPremiumStation, isFirewall, isPremiumSerwer, isCocon, contact } =
    useContext(ConfigContext);
  const { getShopImageConfigs } = useContext(FilesContext);
  const [companyLogo] = getShopImageConfigs(['company-logo']);
  const companyLogoUrl = companyLogo ? resolveImageUrl(companyLogo) : undefined;
  const promotions = getMappedPromotions(banners, 'BIG');

  return (
    <>
      <Head>
        {getStructuredData(getHomeStructuredData(contact, companyLogoUrl))}
        {getStructuredData(getSearchBoxStructuredData(appUrl))}
      </Head>
      <div className={classes.wrapper}>
        {(isBiznesowe || isPremiumStation || isCocon) && <CommonBanner />}
        {isDellowo && <DellBanner numbers={numbers} />}
        {isFirewall && <FirewallBanner />}
        {isPremiumSerwer && (
          <>
            <PremiumSerwerBanner />
            <ConfiguratorProcess className={classes.smallSection} />
            {searchData && (
              <VariantsList
                variants={searchData.search}
                constantFilters={searchData.constantFilters}
                filters={searchData.filters}
                hits={searchData.hits}
                sortValues={premiumSerwerSortValues}
                compactWithAttributes
                withoutAvailabilitySwitchers
                showAll
              />
            )}
          </>
        )}
        {!!promotions.length && (
          <div className={classes.banner}>
            <PromotionBanners
              type="BIG"
              classes={{ arrow: classes.arrow }}
              sizes={{ desktop: { width: 1343, height: 200 }, mobile: { width: 686 } }}
              promotions={promotions}
            />
          </div>
        )}
        {isBiznesowe && (
          <section className={classes.section}>
            <BusinessStandards />
          </section>
        )}
        {isPremiumSerwer && (
          // <Section
          //   classes={{ root: classes.sectionSpacing }}
          //   title={<FormattedMessage {...messages.configuratorProcess} />}
          // >
          //   <ConfiguratorProcess size="big" withId />
          // </Section>
          <div className={classes.sectionSpacing} />
        )}
        {!!homepageProducts?.length && (
          <section className={classes.section}>
            <HomepageProducts homepageProducts={homepageProducts} />
          </section>
        )}
        {!!articleCategories.length &&
          articleCategories.map((articleCategory) => (
            <ArticleCategoryPreview
              key={articleCategory.id}
              name={articleCategory.name}
              slug={articleCategory.slug}
              articles={map(articleCategory.articles, 'article')}
              withFakeLink
              withSpacing
            />
          ))}
        {!!variantsPromotedCount?.aggregate.count && isFirewall && (
          <Section
            classes={{ root: classes.sectionSpacing }}
            title={<FormattedMessage {...messages.recommended} values={{ appShortName: contact.appShortName }} />}
          >
            <RecommendedProducts
              listType="homepage_recommended_products"
              className={classes.section}
              recommendedProductsLength={variantsPromotedCount.aggregate.count}
            />
          </Section>
        )}
        <Section>
          <AboutCompany />
        </Section>
        {!!trustedUsCount?.aggregate.count && <TrustedUs page={routes.home.href} limit={12} />}
        {testimonials && !!testimonials.length && (
          <Section title={<FormattedMessage {...messages.clientsAboutUs} />}>
            <ClientsAboutUs testimonials={testimonials} />
          </Section>
        )}
        {!!variantsPromotedCount?.aggregate.count && !isFirewall && (
          <Section
            title={<FormattedMessage {...messages.recommended} values={{ appShortName: contact.appShortName }} />}
          >
            <RecommendedProducts
              listType="homepage_recommended_products"
              className={classes.section}
              recommendedProductsLength={variantsPromotedCount.aggregate.count}
            />
          </Section>
        )}
        {!isPremiumStation && !isPremiumSerwer && (
          <section className={classes.section}>
            <SearchEngineText />
          </section>
        )}
      </div>
    </>
  );
};

export default withMetatags(Home, { withCanonicalUrl: true });
